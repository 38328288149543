import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import type { SearchParameters, MediaType, QueryType } from '../../types'

interface SearchState {
  query: string
  queryType: QueryType
  type: MediaType
  year: string
  page: number
  types: string[]
}

const initialState: SearchState = {
  page: 1,
  query: 'Pokemon',
  queryType: 'search',
  type: null,
  types: ['movie', 'series', 'episode'],
  year: ''
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateQuery: (state, action: PayloadAction<SearchParameters>) => {
      const { page = 1, query, queryType, type, year } = action.payload
      if (state.queryType !== queryType) state.queryType = queryType
      if (typeof query === 'string' && query.length > 0) state.query = query
      state.type = type ?? null
      if (typeof year === 'string' && year.length > 0) state.year = year
      if (page !== state.page) state.page = page
    }
  },
  selectors: {
    selectPage: state => state.page,
    selectQuery: state => state.query,
    selectQueryType: state => state.queryType,
    selectType: state => state.type,
    selectYear: state => state.year,
    selectValidTypes: state => state.types
  }
})

const { updateQuery } = searchSlice.actions
const { selectValidTypes, selectPage, selectQuery, selectQueryType, selectType, selectYear } = searchSlice.selectors

const selectParameters = createSelector([selectQuery, selectQueryType, selectPage, selectType, selectYear], (query, queryType, page, type, year) => {
  const params: SearchParameters = { query, queryType, type }
  if (page !== 1) params.page = page
  if (typeof year === 'string' && year.length > 0) params.year = year
  return params
})

export { updateQuery, selectValidTypes, selectParameters }
export default searchSlice.reducer
