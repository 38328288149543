import React from 'react'
import { useAppSelector, selectValidTypes, updateQuery, useAppDispatch } from '../store'
import type { SearchParameters, MediaType, QueryType } from '../types'

const SearchForm: React.FC = () => {
  const types = useAppSelector(selectValidTypes)
  const dispatch = useAppDispatch()
  const updateSearchParams = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const query = formData.get('query')
    if (typeof query !== 'string') return
    const parameters: SearchParameters = {
      query,
      queryType: formData.get('queryType') as QueryType,
      year: formData.get('year') as string,
      type: formData.get('type') as MediaType
    }
    dispatch(updateQuery(parameters))
  }
  return (
    <div className='row'>
      <div className='column'>
        <form onSubmit={updateSearchParams}>
          <div className='row row-bottom'>
            <div className='column'>
              <label htmlFor='searchQuery'>Search in title:</label>
              <input type='text' required name='query' placeholder='Pokemon' id='searchQuery' />
            </div>
            <div className='column column-20'>
              <label htmlFor='searchYear'>Filter by year:</label>
              <input type='number' name='year' step='1' min='1800' max='2024' id='searchYear' />
            </div>
            <div className='column column-20'>
              <label htmlFor='searchType'>Filter by type:</label>
              <select name='type' id='searchType'>
                <option value=''>movie/series/episode</option>
                {types.map(type => <option key={type} value={type}>{type}</option>)}
              </select>
            </div>
            <div className='column column-20'>
              <label htmlFor='searchQueryType'>Query type:</label>
              <select name='queryType' id='searchQueryType'>
                <option value='search'>search</option>
                <option value='find'>find</option>
              </select>
            </div>
            <div className='column'>
              <input className='button-primary' type='submit' value='Search' />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SearchForm
