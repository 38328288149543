import React from 'react'
import { updateQuery, useAppDispatch } from '../store'
import type { PaginationProps } from '../types'

const Pagination: React.FC<PaginationProps> = (props) => {
  const { currentPage, pageCount } = props
  const dispatch = useAppDispatch()
  const paginate = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const page = parseInt(formData.get('page') as string)
    dispatch(updateQuery({ page, query: '', queryType: 'search' }))
  }
  return (
    <nav>
      <form onSubmit={paginate}>
        <div className='row row-center'>
          <p className='column'>Showing page {currentPage} of {pageCount}.</p>
          <div className='column column-20'>
            <label htmlFor='searchPagination'>Choose another page:</label>
          </div>
          <div className='column column-10'>
            <input type='number' min='1' max={pageCount} id='searchPagination' required name='page' placeholder={currentPage.toString()} />
          </div>
          <div className='column column-10'><input type='submit' value='Go!' /></div>
        </div>
      </form>
    </nav>
  )
}

export default Pagination
