import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { DetailResponse, SearchResponse, SearchParameters, SearchPayload, DetailPayload, PaginatedSearchResponse } from '../types'

const apikey = process.env.OMDB_API_KEY as string

export const omdbApi = createApi({
  reducerPath: 'omdbApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.omdbapi.com/',
    paramsSerializer: (params) => {
      return new URLSearchParams({ ...params, apikey }).toString()
    }
  }),
  endpoints: (builder) => ({
    details: builder.query<DetailResponse, string>({
      query: (id) => ({ url: '/', params: { i: id } })
    }),
    search: builder.query<PaginatedSearchResponse, SearchParameters>({
      query: ({ query, queryType, year, type, page }) => {
        if (queryType === 'search') {
          const payload: SearchPayload = { s: query }
          if (typeof page === 'number') payload.page = page
          if (typeof year === 'string' && year.length > 0) payload.y = year
          if (typeof type === 'string' && type.length > 0) payload.type = type
          return { url: '/', params: payload }
        }
        const payload: DetailPayload = { t: query }
        if (typeof year === 'string' && year.length > 0) payload.y = year
        if (typeof type === 'string' && type.length > 0) payload.type = type
        return { url: '/', params: payload }
      },
      transformResponse: (r: SearchResponse | DetailResponse, _, arg) => {
        if (r.Response !== 'True') {
          return { Search: [], pageCount: 0, currentPage: 0, totalResults: '', Response: r.Response }
        }
        if (arg.queryType === 'find') {
          const { imdbID, Title, Poster, Type, Year, Response } = r as DetailResponse
          return { Search: [{ imdbID, Title, Poster, Type, Year }], pageCount: 1, currentPage: 1, totalResults: '1', Response }
        }
        const returnValue = r as SearchResponse
        const pageCount = Math.ceil(parseInt(returnValue.totalResults) / 10)
        const currentPage = arg.page ?? 1
        return { ...returnValue, pageCount, currentPage }
      }
    })
  })
})

export const { useDetailsQuery, useSearchQuery } = omdbApi
