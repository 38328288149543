import React from 'react'
import { Outlet } from 'react-router'

const Layout: React.FC = () => {
  return (
    <div className='container'>
      <div className='row'>
        <div className='column'>
          <h1>Invent Analytics | Kaan Boluk</h1>
        </div>
      </div>
      <hr />
      <Outlet />
    </div>
  )
}

export default Layout
