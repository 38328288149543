import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { omdbApi } from '../services/omdb'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import searchReducer, { selectParameters, selectValidTypes, updateQuery } from './slices/search'

export const store = configureStore({
  reducer: {
    search: searchReducer,
    [omdbApi.reducerPath]: omdbApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(omdbApi.middleware)
})

setupListeners(store.dispatch)

export type AppStore = typeof store
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()
export const useAppStore = useStore.withTypes<AppStore>()

export { selectParameters, selectValidTypes, updateQuery }
