import React from 'react'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router'
import Details from './components/Details'
import Search from './components/Search'
import Layout from './components/Layout'
import { store } from './store'

const container = document.getElementById('app') as HTMLElement
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Search />} />
          <Route path='title/:id' element={<Details />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </Provider>
)
