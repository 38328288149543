import React from 'react'
import { useParams } from 'react-router'
import { useDetailsQuery } from '../services/omdb'

const Details: React.FC = () => {
  const { id } = useParams()
  const { data, isLoading } = useDetailsQuery(id as string)
  if (isLoading) return 'loading'
  if (data != null) {
    const hasRatings = data.Ratings.length > 0
    const hasPoster = data.Poster !== 'N/A'
    return (
      <>
        <div className='row'>
          <div className='column'>
            <h2>{data.Title}</h2>
            <p>{data.Year}</p>
          </div>
        </div>
        <div className='row'>
          {hasPoster && (
            <div className='column column-20'>
              <img src={data.Poster} />
            </div>
          )}
          <div className='column'>
            <dl>
              <dt>Genre</dt>
              <dd>{data.Genre}</dd>
              <dt>Plot</dt>
              <dd>{data.Plot}</dd>
              <dt>Director</dt>
              <dd>{data.Director}</dd>
              <dt>Actors</dt>
              <dd>{data.Actors}</dd>
              <dt>Runtime</dt>
              <dd>{data.Runtime}</dd>
            </dl>
            {
              hasRatings && (
                <>
                  <hr />
                  <h4>Ratings</h4>
                  <ul>
                    {data.Ratings.map(rating => <li key={rating.Source}>{rating.Source}: {rating.Value}</li>)}
                  </ul>
                </>
              )
            }
          </div>
        </div>
      </>
    )
  }
}

export default Details
