import React from 'react'
import { Link } from 'react-router'
import { useAppSelector, selectParameters } from '../store'
import { useSearchQuery } from '../services/omdb'
import Pagination from './Pagination'

const SearchResults: React.FC = () => {
  const params = useAppSelector(selectParameters)
  const { data, isLoading } = useSearchQuery(params)
  if (isLoading) return 'loading'
  if (data != null) {
    if (data.Search.length === 0) {
      return (
        <div className='row'>
          <div className='column'>
            <h3>No results found.</h3>
          </div>
        </div>
      )
    }
    return (
      <div className='row'>
        <div className='column'>
          <table>
            <thead>
              <tr>
                <th scope='col'>Title</th>
                <th scope='col'>Year</th>
                <th scope='col'>Type</th>
                <th scope='col'>IMDB ID</th>
              </tr>
            </thead>
            <tbody>
              {data.Search.map(media =>
                <tr key={media.imdbID}>
                  <th scope='row'><Link to={`title/${media.imdbID}`}>{media.Title}</Link></th>
                  <td>{media.Year}</td>
                  <td>{media.Type}</td>
                  <td>{media.imdbID}</td>
                </tr>)}
            </tbody>
          </table>
          {data.pageCount > 1 && <Pagination currentPage={data.currentPage} pageCount={data.pageCount} />}
        </div>
      </div>
    )
  }
}

export default SearchResults
